import React, { useEffect, useState } from "react";
import { Dropdown } from "primereact/dropdown";
import { Checkbox } from "primereact/checkbox";
import { Panel } from "primereact/panel";
import { Facility } from "../../services/form-controller.service";
import { Message } from "primereact/message";
import { InputMask } from "primereact/inputmask";

interface DropdownOption {
  label: string;
  value: any;
}

export interface ControlOneProps {
  dropdownOptions: DropdownOption[];
  dropdownLabel: string;
  fieldName: string;
  fromDateFieldName: string;
  toDateFieldName: string;
  _globalFieldChangeHandler: (e: any) => void;
  _currentFormData?: any;
  _facility: Facility[];
  _costCallback: any;
  _disabled: boolean;
}

const ControlOne: React.FC<ControlOneProps> = ({
  dropdownOptions,
  dropdownLabel,
  fieldName,
  fromDateFieldName,
  toDateFieldName,
  _globalFieldChangeHandler,
  _currentFormData,
  _facility,
  _costCallback,
  _disabled,
}) => {
  const [isEnabled, setIsEnabled] = useState<boolean>(false);
  const [formData, setFormData] = useState<Record<string, any>>({});
  const [message, setMessage] = useState<string>("");
  const [messageType, setMessageType] = useState<"error" | "info" | "warn">(
    "info"
  );
  const [priceTotal, setPriceTotal] = useState<string>("€ 0,00");

  const handleCheckboxChange = (e: any) => {
    const checked = e.target.checked;
    setIsEnabled(checked);
    if (checked) {
      _costCallback({
        selected: true,
        name: fieldName,
        category: _facility[0]?.category,
        selectedOption: "",
        totalCost: 0,
        valid: false,
      });
      _globalFieldChangeHandler(e);
    }
    if (!checked) {
      // Reset fields when checkbox is unchecked
      setFormData((prevData) => ({
        ...prevData,
        [dropdownLabel]: "",
        [fromDateFieldName]: "",
        [toDateFieldName]: "",
      }));
      setPriceTotal("€ 0,00");
      setMessage("");
      _costCallback({
        selected: false,
        name: fieldName,
        category: _facility[0]?.category,
        selectedOption: "",
        totalCost: 0,
        valid: false,
      });

      // we need to call the global field change handler one at a time to clear the formData for the 3 fields
      _globalFieldChangeHandler({
        target: {
          id: dropdownLabel,
          value: "",
        },
      });
      _globalFieldChangeHandler({
        target: {
          id: fromDateFieldName,
          value: "",
        },
      });
      _globalFieldChangeHandler({
        target: {
          id: toDateFieldName,
          value: "",
        },
      });
      _globalFieldChangeHandler({
        target: {
          id: fieldName,
          value: false,
        },
      });
    }
  };

  const calculateDaysBetweenDates = (
    startDate: Date,
    endDate: Date
  ): number => {
    let businessDays = 0;
    let currentDate = new Date(startDate);

    // Ensure the start date is before the end date
    while (currentDate <= endDate) {
      const dayOfWeek = currentDate.getDay();
      // Check if the current day is a business day (Monday to Friday)
      if (dayOfWeek !== 0 && dayOfWeek !== 6) {
        businessDays++;
      }
      // Move to the next day
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return businessDays - 1;
  };

  const parseDateFromEUString = (input: string): Date => {
    if (!input) {
      return new Date(NaN);
    }
    const parts = input.split("-");
    return new Date(Number(parts[2]), Number(parts[1]) - 1, Number(parts[0]));
  };

  const handleChange = (e: any) => {
    const { id, value } = e.target;
    if (id !== dropdownLabel) {
      setFormData({ ...formData, [id]: value });
      _globalFieldChangeHandler(e);
    } else {
      setFormData({
        ...formData,
        [id]: value,
        [fromDateFieldName]: "",
        [toDateFieldName]: "",
      });
      _costCallback({
        selected: true,
        name: fieldName,
        category: _facility[0]?.category,
        selectedOption: "",
        totalCost: 0,
        valid: false,
      });
      setPriceTotal("€ 0,00");
      _globalFieldChangeHandler({
        target: {
          id: fromDateFieldName,
          value: "",
        },
      });

      _globalFieldChangeHandler({
        target: {
          id: toDateFieldName,
          value: "",
        },
      });
      _globalFieldChangeHandler(e);
    }
  };

  useEffect(() => {
    calculateCosts();
  }, [formData]);

  const calculateCosts = () => {
    if (
      formData[dropdownLabel] &&
      formData[fromDateFieldName] &&
      formData[toDateFieldName]
    ) {
      let totalCost = 0;
      const selectedOption = formData[dropdownLabel];
      const relatedFacility = _facility?.find(
        (facility) => facility.option === selectedOption
      );

      if (!relatedFacility) {
        return;
      }

      const fromDate = parseDateFromEUString(formData[fromDateFieldName]);
      const toDate = parseDateFromEUString(formData[toDateFieldName]);
      if (isNaN(fromDate.getTime()) || isNaN(toDate.getTime())) {
        return;
      }

      const daysTotal = calculateDaysBetweenDates(fromDate, toDate);
      if (daysTotal > relatedFacility.max) {
        setMessageType("error");
        setMessage("Max. aantal dagen overschreden.");
      } else {
        setMessage("");
      }

      if (relatedFacility.price_unit === "vast") {
        setPriceTotal(`€ ${relatedFacility.price},00`);
        totalCost = Number(relatedFacility.price);
      } else if (relatedFacility.price_unit === "per dag") {
        const cost = daysTotal * Number(relatedFacility.price);
        setPriceTotal(`€ ${cost},00`);
        totalCost = cost;
      }

      _costCallback({
        selected: true,
        name: relatedFacility.facility,
        category: relatedFacility.category,
        selectedOption: selectedOption,
        relatedDates: {
          from: formData[fromDateFieldName],
          to: formData[toDateFieldName],
        },
        totalCost: totalCost,
        valid: true,
      });
    }
  };

  useEffect(() => {
    if (_currentFormData) {
      if (_currentFormData[fieldName] == true) {
        setIsEnabled(_currentFormData[fieldName]);
        setFormData({
          ..._currentFormData,
          [fromDateFieldName]: _currentFormData[fromDateFieldName],
          [toDateFieldName]: _currentFormData[toDateFieldName],
        });
        calculateCosts();
      }
    }
  }, []);

  const renderHeader = () => (
    <>
      <Checkbox
        disabled={_disabled}
        checked={isEnabled}
        id={fieldName}
        onChange={handleCheckboxChange}
        style={{ marginRight: "1rem" }}
      />
      {fieldName}
      {message && <Message severity={messageType} text={message} />}
    </>
  );

  return (
    <Panel header={renderHeader()}>
      <div
        style={{ display: "flex", marginBottom: "1rem", alignItems: "center" }}
      >
        <div style={{ width: "40%" }}>
          <label htmlFor={dropdownLabel}>{fieldName}:</label>
        </div>
        <div style={{ width: "40%" }}>
          <Dropdown
            id={dropdownLabel}
            options={dropdownOptions}
            onChange={handleChange}
            value={formData[dropdownLabel]}
            disabled={!isEnabled}
          />
        </div>
        <div style={{ width: "20%" }}>
          <h5>{priceTotal}</h5>
        </div>
      </div>

      <div style={{ display: "flex", marginBottom: "1rem" }}>
        <div style={{ width: "40%" }}>
          <label htmlFor={fromDateFieldName}>Datum van:</label>
        </div>
        <div style={{ width: "40%" }}>
          <InputMask
            mask="99-99-9999"
            id={fromDateFieldName}
            onChange={handleChange}
            value={formData[fromDateFieldName]}
            disabled={!isEnabled}
          />
        </div>
      </div>

      <div style={{ display: "flex", marginBottom: "1rem" }}>
        <div style={{ width: "40%" }}>
          <label htmlFor={toDateFieldName}>Datum tot:</label>
        </div>
        <div style={{ width: "40%" }}>
          <InputMask
            id={toDateFieldName}
            mask="99-99-9999"
            onChange={handleChange}
            value={formData[toDateFieldName]}
            disabled={!isEnabled}
          />
        </div>
      </div>
    </Panel>
  );
};

export default ControlOne;
