//#region  CSS/SCSS
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import "primereact/resources/primereact.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "remixicon/fonts/remixicon.css";
import "./App.css";
import AppHeader from "./components/header";
import AppFooter from "./components/footer";
import FormController from "./components/form-controller";
import * as Sentry from "@sentry/react";

const logError = async (error: unknown, context?: string) => {
  console.error("Error logged:", { error, context });
  const log = await fetch("/api/formdata/ERROR", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ context, error }),
  });
};

window.onerror = async (message, source, lineno, colno, error) => {
  await logError(message, `Error at ${source}:${lineno}:${colno}`);
};

window.onunhandledrejection = async (event) => {
  await logError(event.reason, "Unhandled Promise Rejection");
};

function App() {
  return (
    <div className="app">
      <AppHeader />
      <div className="mainAppContainer">
        <FormController />
      </div>
      <AppFooter />
    </div>
  );
}

export default App;
