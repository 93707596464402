

const AppHeader = () => {
  

  return (
    <div className='headerPanel'>
<div style={{display: 'flex', width: '100%', height: '100%'}}>
      <img src="westland-logo.png" height="75"/>
        <div className="headerTitle">
        <h2>Online Uitvaart Formulier</h2>
        </div>
        
        <div style={{ display: 'flex',fontSize: '24px',textAlign: 'center', minWidth: '700px', marginLeft: 'auto', paddingTop: '20px'}}>
        <a style={{padding: '20px', color: 'black', textDecoration: 'none'}} href="https://westland.liminil.net" target="_blank">Nieuwe aanvraag</a> 
        <a style={{padding: '20px', color: 'black', textDecoration: 'none'}}  href="/uitleg" target="_blank">Uitleg</a> 
        <a style={{padding: '20px', color: 'black', textDecoration: 'none'}} href="/contact" target="_blank">Contact</a>
        </div>
    </div></div>
)
}

export default AppHeader;