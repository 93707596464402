// ContactTable.tsx
import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Card } from 'primereact/card';

interface ContactInfo {
    location: string;
    contactName: string;
    phoneNumber: string;
    email: string;
}

const contactData: ContactInfo[] = [
    { location: "'s-Gravenzande", contactName: "André van Rossen", phoneNumber: "06-14547866", email: "begraafplaatssgravenzande@gemeentewestland.nl" },
    { location: "Maasdijk", contactName: "André van Rossen", phoneNumber: "06-14547866", email: "begraafplaatsmaasdijk@gemeentewestland.nl" },
    { location: "Monster", contactName: "André van Rossen", phoneNumber: "06-14547866", email: "begraafplaatsmonster@gemeentewestland.nl" },
    { location: "Naaldwijk", contactName: "Gilbert Vlaming", phoneNumber: "06-42529867", email: "begraafplaatsnaaldwijk@gemeentewestland.nl" },
    { location: "Wateringen", contactName: "Gilbert Vlaming", phoneNumber: "06-42529867", email: "begraafplaatswateringen@gemeentewestland.nl" },
    { location: "De Lier", contactName: "Gilbert Vlaming", phoneNumber: "06-42529867", email: "begraafplaatsdelier@gemeentewestland.nl" }
];


interface ContactInfo_1 {
    role: string;
    name: string;
    phone: string;
    email: string;
}

const contactData_1: ContactInfo_1[] = [
    {
        role: 'Begraafplaatsadministratie',
        name: 'Miranda Lem',
        phone: '06-10974044',
        email: 'uitvaart@gemeentewestland.nl',
    },
    {
        role: 'Begraafplaatsadministratie',
        name: 'Mariëlle Westhoff',
        phone: '06-21802753',
        email: 'uitvaart@gemeentewestland.nl',
    },
];

const ContactTable: React.FC = () => {
    return (
        <Card title="Cemetery Contacts" style={{ margin: '2rem', paddingTop: 100 }}>
            <DataTable value={contactData}  rows={10} tableStyle={{ minWidth: '50rem' }}>
                <Column field="location" header="Locatie" style={{ width: '25%' }}></Column>
                <Column field="contactName" header="Naam" style={{ width: '25%' }}></Column>
                <Column field="phoneNumber" header="Telefoonnummer" style={{ width: '25%' }}></Column>
                <Column field="email" header="E-mailadres" style={{ width: '25%' }}></Column>
            </DataTable>
            <br />
            <p>Heeft u vragen over of problemen met het invullen van het formulier, neem dan contact op met de begraafplaatsadministratie</p>
             <DataTable value={contactData_1} responsiveLayout="scroll">
                <Column field="role" header="Rol" />
                <Column field="name" header="Naam" />
                <Column field="phone" header="Telefoonnummer" />
                <Column field="email" header="E-mailadres" />
            </DataTable>
        </Card>
    );
};

export default ContactTable;
