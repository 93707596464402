// ContactTable.tsx
import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Card } from "primereact/card";

const HelpView: React.FC = () => {
  return (
    <Card title="Uitleg" style={{ margin: "2rem", padding: 50  ,paddingTop: 100, width: "100%"}}>
      <h1>Belangrijk</h1>
      <p>
        Deel de link Gemeente Westland (liminil.net) met uw collega’s en
        medewerkers!!
      </p>

      <p>
        Het KvK nummer dat u op de eerste pagina in moet vullen, is het nummer
        wat bij gemeente Westland bekend is. De aanslagen werden en worden naar
        dit adres gezonden (wat in de Kvk staat). Indien u het onjuiste KvK
        nummer invult, kunt u het online formulier niet invullen! Neem dan
        contact op met de begraafplaatsadministratie.
      </p>

      <p>
        Na invullen van elke pagina, klikt u linksboven in het formulier op de
        button volgende. Zo komt u in het volgende scherm en kunt u het gehele
        formulier invullen.
      </p>

      <p>
        U kunt tussentijds ook stoppen met het invullen van het formulier. Als u
        op de button opslaan klikt, dan volgt deze melding:
      </p>

      <br />
      <img src="/help-image.png" width="50%" />
        <br />
      <p>
        Klik vervolgens op de button: Versturen en er wordt een mail met een
        link naar uw email box verzonden. Het kan door onvoorziene
        omstandigheden gebeuren dat deze mail in uw SPAM terecht komt. U kunt
        dan op een later tijdstip op de link in de mail klikken om verder te
        gaan met het invullen.
      </p>

      <p>
        Als u op de laatste pagina van het formulier bent gekomen, dan dient u
        ná de ondertekening (u kunt met de muis uw handtekening zetten of met
        een teken-pad) op de button: Bevestig de handtekening te klikken.
        Vervolgens klikt u (linksboven in het formulier) op de button: Aanvraag
        indienen.
      </p>

      <p>
        Het formulier wordt nu naar de beheerder van de begraafplaats verzonden.
        U krijgt ook het formulier (in pdf) in uw email box.
      </p>

      <p>
        Mochten er veranderingen plaats hebben gevonden tijdens een uitvaart
        (bijvoorbeeld wel of geen geluid of geen klok) dan moet er ten alle
        tijden binnen 2 dagen na de uitvaart contact opgenomen worden met de
        beheerder van de begraafplaats. De beheerder zorgt er dan voor dat de
        wijziging wordt aangepast. Hierna ondertekend hij het formulier en de
        aangepaste versie vervolgens doorsturen naar de administratie. Als een
        verandering niet binnen deze 2 dagen na de uitvaart wordt doorgegeven,
        dan kan het formulier niet meer worden gewijzigd. U ontvangt de aanslag
        met de bedragen zoals u heeft aangeleverd. Als u het niet eens bent met
        de gefactureerde bedragen, dan dient u in bezwaar te gaan.
      </p>
    </Card>
  );
};

export default HelpView;
