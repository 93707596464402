

export interface ApiOptions {
    url: string;
    method: string;
    data?: any;
}

export interface SessionObject {
    id: string;
    formData: string;
    status: string;
    adminEmail: string;
    userEmail: string;
    createdOn: string;
    adminSignature: string;
    directorSignature: string;
    selectedFacilities: string;
    burialCosts: string;
}
export default class ClientDataService {

    baseUrl: string;

    constructor(baseUrl: string) {
        this.baseUrl = baseUrl;
    }

    public async getControlData() {
        const controlDataLists: string[] = ['HOLIDAYS', 'LOCATIONS', 'CHAMBERNUMBERS', 'FUNERALTYPES', 'COSTS', 'FACILITIES', 'NEXTNUMBERS'];
        let controlDataMap: any = {};

        for (let i = 0; i < controlDataLists.length; i++) {
            const options = {
                url: `${this.baseUrl}/api/controldata/${controlDataLists[i]}`,
                method: 'GET'
            }
            let result = await this.makeApiCall(options);
            controlDataMap[controlDataLists[i] as string] = result
        }

        return controlDataMap;

    }
    public async getFormData(sessionId: string) {
        const options = {
            url: `${this.baseUrl}/api/formdata/${sessionId}`,
            method: 'GET'
        }
        return await this.makeApiCall(options)
    }

    public async postFormData(data: SessionObject) {


        const options = {
            url: `${this.baseUrl}/api/formdata/${data.id}`,
            method: 'POST',
            data: data
        }
        return await this.makeApiCall(options)
    }

    public async updateFormData(data: SessionObject) {


        const options = {
            url: `${this.baseUrl}/api/formdata/${data.id}`,
            method: 'PUT',
            data: data
        }
        return await this.makeApiCall(options)
    }

    private async makeApiCall(options: ApiOptions) {

        try {

            if (options.method === 'GET') {

                const result = await fetch(options.url, {
                    headers: {
                        'content-type': 'application/json'
                    }
                });
                const r = await result.json();
                return r;

            } else {

                const result = await fetch(options.url, {
                    method: options.method,
                    body: JSON.stringify(options.data),
                    headers: {
                        'content-type': 'application/json'
                    }
                })
                return await result.json();

            }

        } catch (e: any) {
            return { error: e }
        }
    }

}


